/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import ENV from 'embercom/config/environment';
import { get, put, post } from 'embercom/lib/ajax';
import {
  AI_AGENT_ENTITY_TYPES,
  AI_COPILOT_ENTITY_TYPES,
  CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
  FinAIType,
  IMPORT_SOURCE_STATUSES,
  KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES,
  KNOWLEDGE_HUB_ENTITIES,
  KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES,
} from 'embercom/lib/knowledge-hub/constants';
import type Folder from 'embercom/models/content-service/folder';
import { EntityType } from 'embercom/models/data/entity-types';
import type ImportSource from 'embercom/models/import-service/import-source';
import { SyncBehaviorType, type SourceType } from 'embercom/models/import-service/import-source';
import { Tree, type TreeItem } from 'embercom/objects/tree-list';
//@ts-ignore
import type RouterService from '@ember/routing/router-service';
import { capitalize } from '@ember/string';
import { type KnowledgeUsageSummary } from 'embercom/components/knowledge-hub/overview/types';
import KnowledgeHubBulkActionsUpdate from 'embercom/components/notifications/knowledge-hub-bulk-actions-update';
import { CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import type KnowledgeHubApi from 'embercom/lib/knowledge-hub/list-api';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';
import {
  type SyncErrorType,
  type SyncSourceType,
  type SyncStatus,
} from 'embercom/models/knowledge-hub/sync-source-wrapper';
import {
  KNOWLEDGE_BLANK_QUERY_PARAMS,
  type KnowledgeHubApiQueryParams,
} from 'embercom/lib/knowledge-hub/list-api';
import {
  type ContentImportRunEvent,
  EVENT_SERVICE_ID,
} from 'embercom/services/content-import-service';
import {
  FIN_SUPPORTED_LANGUAGES,
  ENGLISH_VARIATIONS,
} from 'embercom/lib/ai-content-library/constants';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { type RouteInfoWithAttributes } from '@ember/routing/route-info';
import { activeFolderFromModel } from 'embercom/components/knowledge-hub/folders/tree-helper';
import type ArticleGroup from 'embercom/models/articles/article-group';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import KnowledgeTree from 'embercom/objects/knowledge-hub/knowledge-tree';
import type Store from '@ember-data/store';
import { getOwner } from '@ember/application';
import type ApplicationInstance from '@ember/application/instance';
import type ArrayProxy from '@ember/array/proxy';
import type KnowledgeHubDrawerEditorService from './knowledge-hub-drawer-editor-service';

export interface UpdateImportSourceStatusEvent {
  import_source_id: number;
  sync_behavior: number;
  status: string;
}

export interface ImportServiceImportSourceDeletedEvent {
  import_source_id: number;
  is_deleted: boolean;
}

export interface ImportServiceImportEvent {
  import_source_id: number;
  import_source_name: string;
}

export interface BulkActionProgressionEvent {
  admin_id: number;
  status: BulkActionProgressionValues;
  bulk_action: BulkActionType;
  unaffected_entity_types: number[];
  all_entities_unaffected: boolean;
  language_code?: string;
  destination_folder_id?: string;
}

export interface ZendeskImportStatusChangedEvent {
  adminId: number;
  status: ZendeskImportStatus;
}

export enum ZendeskImportStatus {
  Completed = 0,
  Failed = 1,
}

export enum BulkActionProgressionValues {
  Complete = 0,
  InvalidFolder = 1,
}

export type ConfluenceSpace = {
  id: string;
  name: string;
};

export type ConfluenceSite = {
  id: string;
  name: string;
  url: string;
};

type OverviewChecklistAttributes = {
  used_ai_copilot: boolean;
  ai_agent_enabled: boolean;
  has_help_center_collections: boolean;
  has_published_article_within_collection: boolean;
  has_non_precanned_content?: boolean;
  ai_agent_fallback_search_locale_enabled: boolean;
};

export enum BulkActionType {
  ENABLE_COPILOT_AVAILABILITY = 'enable_copilot_availability',
  DISABLE_COPILOT_AVAILABILITY = 'disable_copilot_availability',
  ENABLE_CHATBOT_AVAILABILITY = 'enable_chatbot_availability',
  DISABLE_CHATBOT_AVAILABILITY = 'disable_chatbot_availability',
  ARTICLE_PUBLISH = 'article_publish',
  ARTICLE_UNPUBLISH = 'article_unpublish',
  MOVE_TO_FOLDER = 'move_to_folder',
  UPDATE_LANGUAGE = 'update_language',
  CHANGE_SEGMENT = 'change_segment',
  UPDATE_AUTHOR = 'update_author',
  UPDATE_TAGS = 'update_tags',
  MOVE_TO_COLLECTION = 'move_to_collection',
  DELETE = 'delete',
}

export const BULK_ACTION_TO_NOTIFICATION_MAP: Record<BulkActionType, string> = {
  [BulkActionType.ENABLE_COPILOT_AVAILABILITY]:
    'knowledge-hub.filterable-list.bulk-actions.ai-copilot-enable',
  [BulkActionType.DISABLE_COPILOT_AVAILABILITY]:
    'knowledge-hub.filterable-list.bulk-actions.ai-copilot-disable',
  [BulkActionType.ENABLE_CHATBOT_AVAILABILITY]:
    'knowledge-hub.filterable-list.bulk-actions.ai-agent-enable',
  [BulkActionType.DISABLE_CHATBOT_AVAILABILITY]:
    'knowledge-hub.filterable-list.bulk-actions.ai-agent-disable',
  [BulkActionType.ARTICLE_PUBLISH]: 'knowledge-hub.filterable-list.bulk-actions.article-publish',
  [BulkActionType.ARTICLE_UNPUBLISH]:
    'knowledge-hub.filterable-list.bulk-actions.article-unpublish',
  [BulkActionType.MOVE_TO_FOLDER]: 'knowledge-hub.filterable-list.bulk-actions.move-to-folder',
  [BulkActionType.UPDATE_LANGUAGE]: 'knowledge-hub.filterable-list.bulk-actions.update-language',
  [BulkActionType.CHANGE_SEGMENT]: 'knowledge-hub.filterable-list.bulk-actions.update-audience',
  [BulkActionType.UPDATE_TAGS]: 'knowledge-hub.filterable-list.bulk-actions.update-tags',
  [BulkActionType.DELETE]: 'knowledge-hub.filterable-list.bulk-actions.delete',
  [BulkActionType.UPDATE_AUTHOR]: 'knowledge-hub.filterable-list.bulk-actions.update-author',
  [BulkActionType.MOVE_TO_COLLECTION]:
    'knowledge-hub.filterable-list.bulk-actions.move-to-collection',
};

export const IMPORT_JOB_STATUS_EVENT_ID = 'ImportServiceImportJobStatusChanged';
export const IMPORT_JOB_DELETE_STATUS_ID = 'ImportServiceImportSourceWithContentDeleted';
export const BULK_ACTION_PROGRESSION_ID = 'KnowledgeBaseBulkActionProgressUpdate';
export const ARTICLE_SYNC_SETTINGS_DELETED = 'ArticleSyncSettingsDeleted';
export const ARTICLE_IMPORT_COMPLETED = 'ImportCompleted';
export const ARTICLE_IMPORT_STATUS_EVENT_ID = 'ZendeskImportStatusChanged';

type AIType = 'agent' | 'copilot';

const MINIMUM_SOURCE_COUNT = 1;
const MINIMUM_CONVERSATION_EXCERPTS = 10;
const MINIMUM_OPTIMIZE_AI_SOURCE_COUNT = 2;

export type UsageSummaryResponse = {
  [key: number]: {
    entity_type: number;
    all: number;
    agent: number;
    copilot: number;
    help_center: number;
    sync_sources: SyncSourceWrapperResponse[];
  };
};

export type SyncSourceWrapperResponse = {
  entity_type: number;
  source_id: number;
  source_type: SyncSourceType;
  title?: string;
  folder_id?: number;
  status: SyncStatus;
  last_synced_at: string;
  error_type?: SyncErrorType;
  site_url?: string | null;
  usage: {
    all: number;
    agent: number;
    copilot: number;
    agent_or_copilot: number;
    help_center: number;
  };
};

export default class KnowledgeHubService extends Service {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare helpCenterService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intercomEventService: any;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  @tracked api: KnowledgeHubApi | undefined;
  @tracked spaces: ConfluenceSpace[] = [];
  @tracked knowledgeTree?: KnowledgeTree;
  @tracked hasFetchedFolders = false;
  @tracked availableLocaleIds: string[] = [];
  @tracked usageSummary?: KnowledgeUsageSummary;
  @tracked declare overviewChecklistAttributes: OverviewChecklistAttributes;
  @tracked declare activeTreeItem: TreeItem<Folder> | undefined;
  @tracked hasFetchedArticleCollections = false;

  setActiveItem(item?: TreeItem<Folder>) {
    if (this.activeTreeItem) {
      this.activeTreeItem.isActive = false;
    }
    if (item) {
      item.isActive = true;
    }
    this.activeTreeItem = item;
  }

  expandTreeToFolder(folder: Folder) {
    let folderPath = folder.folderPath;
    let treeLevel: TreeItem | undefined = this.tree.children.firstObject;
    let treeItem: TreeItem | undefined = undefined;
    if (treeLevel) {
      treeLevel.isExpanded = true;
    }
    for (let folder of folderPath) {
      treeItem = treeLevel!.childItemForDataObject(folder);
      if (treeItem) {
        treeItem.isExpanded = true;
        treeLevel = treeItem;
      }
    }
    this.setActiveItem(treeItem);
  }

  async updateActiveItem() {
    let currentRoute = this.router.currentRoute as RouteInfoWithAttributes;
    let activeModel = currentRoute.attributes;
    let activeFolder = await activeFolderFromModel(activeModel);
    if (activeFolder) {
      this.expandTreeToFolder(activeFolder);
    } else if (currentRoute.name === 'apps.app.knowledge-hub.view' && !activeFolder) {
      this.setActiveItem(this.tree.children.firstObject);
    } else {
      this.setActiveItem(undefined);
    }
  }

  get currentAdminCanManageContent() {
    return this.permissionsService.currentAdminCan(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
  }

  async getUsageSummary(): Promise<UsageSummaryResponse> {
    return get('/ember/knowledge_base/overview/usage_summary', {
      app_id: this.appService.app.id,
    });
  }

  private storeSyncSources(usageSummaryResponse: UsageSummaryResponse) {
    let existingSourceIds: Array<SyncSourceWrapper['id']> = this.store
      .peekAll('knowledge-hub/sync-source-wrapper')
      .mapBy('id');

    let syncSources: SyncSourceWrapperResponse[] = [];
    Object.values(usageSummaryResponse).forEach((entity) => {
      syncSources.push(...entity.sync_sources);
    });
    syncSources.forEach((syncSource) => {
      let id = `${syncSource.source_type}-${syncSource.source_id}`;
      let normalizedObject = this.store.normalize('knowledge-hub/sync-source-wrapper', {
        ...syncSource,
        id,
      });
      this.store.push(normalizedObject);
      existingSourceIds.removeObject(id);
    });

    // Remove sources that were in the store but are no longer in the response
    existingSourceIds.forEach((id) => {
      let deletedSource = this.store.peekRecord('knowledge-hub/sync-source-wrapper', id);
      if (deletedSource) {
        this.store.unloadRecord(deletedSource);
      }
    });
  }

  async fetchKnowledgeUsageSummary(): Promise<KnowledgeUsageSummary> {
    let response: UsageSummaryResponse = await this.getUsageSummary();
    this.storeSyncSources(response);
    this.usageSummary = response;
    return this.usageSummary;
  }

  async onSummaryChange(): Promise<KnowledgeUsageSummary> {
    return this.fetchKnowledgeUsageSummary();
  }

  async fetchImportSources(): Promise<ArrayProxy<ImportSource>> {
    return this.store.findAll('import-service/import-source', { reload: true });
  }

  async fetchAvailableLocales(): Promise<void> {
    let messengerSettingsLocaleIds = await taskFor(this.getMessengerSettingLocaleIds).perform();
    let helpCenterLocaleIds = this.getHelpCenterLocaleIds();
    this.availableLocaleIds = Array.from(
      new Set(messengerSettingsLocaleIds.concat(helpCenterLocaleIds)),
    );
  }

  async fetchAvailableLocalesFromMessengerAndHelpCenter() {
    let promises: [Promise<string[]>, Promise<void>?] = [
      taskFor(this.getMessengerSettingLocaleIds).perform(),
    ];

    // only fetch when there's no help centers yet and fetching is not inflight
    if (!this.helpCenterService.fetchingSite && this.helpCenterService.allSites.length === 0) {
      promises.push(this.helpCenterService.forceFetchSites());
    }

    let [messengerSettingsLocaleIds] = await Promise.all(promises);

    let helpCenterLocaleIds = this.getHelpCenterLocaleIds();
    this.availableLocaleIds = Array.from(
      new Set(messengerSettingsLocaleIds.concat(helpCenterLocaleIds)),
    );
  }

  get allSupportedLocaleIds(): string[] {
    let finSupportedLocaleIds = FIN_SUPPORTED_LANGUAGES.map((language) => language.locale);

    if (this.appService.app.canUseEnglishVariations) {
      return [...finSupportedLocaleIds, ...ENGLISH_VARIATIONS];
    }

    return finSupportedLocaleIds;
  }

  async createImportSource(createParams: {
    sourceType: SourceType;
    [key: string]: any;
  }): Promise<ImportSource> {
    let newSource = this.store.createRecord('import-service/import-source', {
      app_id: this.appService.app.id,
      context: this.store.createFragment('import-service/import-source-context'),
      ...createParams,
    });
    await newSource.save();
    return newSource;
  }

  getOAuthURL(importSourceId: string) {
    return `/auth/import_service/authorize?app_id=${this.appService.app.id}&import_source_id=${importSourceId}`;
  }

  getSearchObjectTypes(aiType?: FinAIType): EntityType[] {
    if (aiType === FinAIType.AGENT) {
      return [...AI_AGENT_ENTITY_TYPES];
    } else if (aiType === FinAIType.COPILOT) {
      return [...AI_COPILOT_ENTITY_TYPES];
    }
    return [...KNOWLEDGE_HUB_ENTITIES];
  }

  notifySourceStateChangeConfirmation(message: string, sourceId: string) {
    let key = `source-id-${sourceId}`;
    this.notificationsService.removeNotification(key);
    this.notificationsService.notifyConfirmation(message, ENV.APP._5000MS, key);
  }

  notifyBulkActionCompleted(
    action: BulkActionType,
    unaffectedEntityTypes: number[],
    language_code?: string,
    destinationFolderId?: string,
  ) {
    let messageType = unaffectedEntityTypes.length > 0 ? 'partial' : 'sync';
    let unaffectedContentNames = unaffectedEntityTypes.map((entityType) =>
      capitalize(this.intl.t(`knowledge-hub.content-type-plural.${entityType}`)),
    );
    let formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
    let unaffectedContentList = formatter.format(unaffectedContentNames);

    let bulkActionName = this.intl.t(`${BULK_ACTION_TO_NOTIFICATION_MAP[action]}.label`);
    let language = language_code ? this.intl.t(`app.lib.languages.language.${language_code}`) : '';
    let { folderName, folderLink } = destinationFolderId
      ? this.destinationFolderDetails(destinationFolderId)
      : { folderName: '', folderLink: '' };

    let message = this.intl.t(
      `${BULK_ACTION_TO_NOTIFICATION_MAP[action]}.${messageType}-success-banner`,
      {
        htmlSafe: true,
        contentList: unaffectedContentList,
        bulkAction: bulkActionName,
        language,
        folderName,
        folderLink,
        articleLink: unaffectedEntityTypes.includes(EntityType.ArticleContent)
          ? this.intl.t('knowledge-hub.filterable-list.bulk-actions.shared.link-to-article', {
              url: 'https://www.intercom.com/help/en/articles/9459957-enable-or-disable-content-for-fin-ai-agent-and-ai-copilot',
              htmlSafe: true,
            })
          : '',
      },
    );

    // If there's more complex info show for 20 seconds
    // Else show for 5 seconds
    let duration = unaffectedEntityTypes.length > 0 ? ENV.APP._1000MS * 20 : ENV.APP._5000MS;

    this.notificationsService.notifyConfirmationWithModelAndComponent(
      {
        message,
      },
      KnowledgeHubBulkActionsUpdate,
      duration,
    );
  }

  notifyNoEntitiesAffected(action: BulkActionType) {
    let message = this.intl.t(`${BULK_ACTION_TO_NOTIFICATION_MAP[action]}.no-entities-affected`, {
      url: 'https://www.intercom.com/help/en/articles/9459957-enable-or-disable-content-for-fin-ai-agent-and-ai-copilot',
      htmlSafe: true,
    });
    let duration = ENV.APP._1000MS * 10;
    this.notificationsService.notifyErrorWithModelAndComponent(
      {
        message,
        error: true,
      },
      KnowledgeHubBulkActionsUpdate,
      duration,
    );
  }

  private destinationFolderDetails(destinationFolderId: string): {
    folderName: string;
    folderLink: string;
  } {
    let folderName = '';
    let folderLink = '';
    if (destinationFolderId === 'root') {
      folderName = this.intl.t('knowledge-hub.content');
      folderLink = this.router.urlFor('apps.app.knowledge-hub.all-content');
    } else {
      let folder = this.store.peekRecord('content-service/folder', destinationFolderId);
      folderName = folder.displayName;
      folderLink = folder.url;
    }
    return { folderName, folderLink };
  }

  @task
  *fetchSites(importSourceId: string): TaskGenerator<ConfluenceSite[]> {
    let response = yield get('/ember/import_service/import_sources/sites', {
      app_id: this.appService.app.id,
      import_source_id: importSourceId,
    });
    let json = response as unknown as { sites: ConfluenceSite[] };

    return json.sites;
  }

  @task
  *fetchSpacesForSite(importSourceId: string, siteId: string): TaskGenerator<ConfluenceSpace[]> {
    let response = yield get('/ember/import_service/import_sources/spaces', {
      app_id: this.appService.app.id,
      import_source_id: importSourceId,
      site_id: siteId,
    });

    let json = response as unknown as ConfluenceSpace[];
    return json;
  }

  async removeImportSource(source: ImportSource | ContentImportSource) {
    return new Promise<void>(async (resolve, reject) => {
      await source
        .destroyRecord()
        .then(() => resolve())
        .catch((error) => {
          source.rollbackAttributes();
          reject(error);
        });
    });
  }

  async removeEmptySources() {
    let importSources = await this.fetchImportSources();
    let emptySources = importSources.filter((source) => source.emptySource);
    await Promise.all(emptySources.map((source) => this.removeImportSource(source)));
  }

  async import(importSourceId: string): Promise<void> {
    return await post('/ember/import_service/import_sources/import', {
      app_id: this.appService.app.id,
      import_source_id: importSourceId,
    });
  }

  async startImport(importSourceId: string, syncBehavior = SyncBehaviorType.Sync): Promise<void> {
    return await post('/ember/import_service/import_sources/start_import', {
      app_id: this.appService.app.id,
      import_source_id: importSourceId,
      sync_behavior: syncBehavior,
    });
  }

  async updateImportSourceWithParam(
    importSourceId: string,
    contextUpdates: { [key: string]: any },
  ): Promise<void> {
    return await put('/ember/import_service/import_sources/update_import', {
      app_id: this.appService.app.id,
      import_source_id: importSourceId,
      context_updates: contextUpdates,
    });
  }

  loadKnowledgeTree() {
    if (!this.knowledgeTree) {
      this.knowledgeTree = new KnowledgeTree(getOwner(this) as ApplicationInstance);
    }
  }

  get tree() {
    return this.knowledgeTree?.tree || new Tree();
  }

  subscribeToSourceSyncEvents(): void {
    this.subscribeToImportServiceUpdates();
    this.realTimeEventService.subscribeTopics(['import-completed']);
    this.realTimeEventService.on(IMPORT_JOB_STATUS_EVENT_ID, this, 'reloadSummaryForImportSource');
    this.realTimeEventService.on(
      IMPORT_JOB_DELETE_STATUS_ID,
      this,
      'reloadSummaryForDeleteImportSource',
    );
    this.realTimeEventService.on(EVENT_SERVICE_ID, this, 'reloadSummaryForWebPages');
    this.realTimeEventService.on(ARTICLE_IMPORT_COMPLETED, this, 'onSummaryChange');
    this.realTimeEventService.on(
      ARTICLE_IMPORT_STATUS_EVENT_ID,
      this,
      'notifyZendeskImportStatusChange',
    );
  }

  unsubscribeToSourceSyncEvents(): void {
    this.realTimeEventService.off(IMPORT_JOB_STATUS_EVENT_ID, this, 'reloadSummaryForImportSource');
    this.realTimeEventService.off(
      IMPORT_JOB_DELETE_STATUS_ID,
      this,
      'reloadSummaryForDeleteImportSource',
    );
    this.realTimeEventService.off(EVENT_SERVICE_ID, this, 'reloadSummaryForWebPages');
    this.realTimeEventService.off(ARTICLE_IMPORT_COMPLETED, this, 'onSummaryChange');
    this.realTimeEventService.off(
      ARTICLE_IMPORT_STATUS_EVENT_ID,
      this,
      'notifyZendeskImportStatusChange',
    );
  }

  notifyZendeskImportStatusChange(event: ZendeskImportStatusChangedEvent) {
    if (event.adminId !== this.appService.app.currentAdmin.id) {
      return;
    }

    if (event.status === ZendeskImportStatus.Completed) {
      this.notificationsService.notifyConfirmation(
        this.intl.t('knowledge-hub.sync-modal.zendesk.import-completion-message'),
      );
    } else if (event.status === ZendeskImportStatus.Failed) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.sync-modal.zendesk.import-failed-message'),
      );
    }
  }

  async reloadSummaryForWebPages(event: ContentImportRunEvent) {
    if (event?.event_type === 'completed' || event?.event_type === 'failed') {
      this.onSummaryChange();
    }
  }

  async reloadSummaryForImportSource(event: UpdateImportSourceStatusEvent) {
    if (
      event.status === IMPORT_SOURCE_STATUSES.success ||
      event.status === IMPORT_SOURCE_STATUSES.failed
    ) {
      this.onSummaryChange();
    }
  }

  async reloadSummaryForDeleteImportSource(event: ImportServiceImportSourceDeletedEvent) {
    if (event.is_deleted) {
      let sources = this.store.peekAll('knowledge-hub/sync-source-wrapper');
      let sourceToDelete = sources.filter(
        (src: SyncSourceWrapper) => src.sourceId === event.import_source_id,
      )[0];
      sourceToDelete.deleteRecord();
      sourceToDelete.unloadRecord();
      this.onSummaryChange();
    }
  }

  subscribeToImportServiceUpdates(): void {
    this.realTimeEventService.subscribeTopics([
      'import-service-import-job-status-changed',
      'import-service-import-source-with-content-deleted',
      'import-service-import',
    ]);
  }

  // Gets the folder models setup locally and avoids duplicate requests
  // You should probably call the `fetchFoldersOnce` method instead of this one
  // This method is used if you just want to be sure that the folders are fetched and don't need the models now.
  @task({ drop: true })
  *fetchFolders(): TaskGenerator<Folder[]> {
    if (this.hasFetchedFolders) {
      return yield this.store.peekAll('content-service/folder');
    }
    this.hasFetchedFolders = true;
    return yield this.store.findAll('content-service/folder');
  }

  // Gets the folder models setup locally and avoids duplicate requests
  // This method will return the folder models as soon as they have been retrieved.
  // If the models are already available locally, it will not make a network request.
  async fetchFoldersOnce(forceRefresh = false): Promise<Folder[] | ArrayProxy<Folder>> {
    if (this.loadingFolders) {
      return await taskFor(this.fetchFolders).lastRunning!;
    } else {
      if (forceRefresh) {
        return await this.forceFetchFolders();
      }
      return await taskFor(this.fetchFolders).perform();
    }
  }

  async forceFetchFolders(): Promise<ArrayProxy<Folder>> {
    return this.store.findAll('content-service/folder', { reload: true });
  }

  get loadingFolders() {
    return taskFor(this.fetchFolders).isRunning;
  }

  @task({ drop: true })
  *fetchArticleCollectionsTask(): TaskGenerator<ArticleGroup[]> {
    let shouldReload = !this.hasFetchedArticleCollections;
    return yield this.store.findAll('articles/article-group', { reload: shouldReload });
  }

  async fetchArticleCollections(): Promise<ArticleGroup[]> {
    return await taskFor(this.fetchArticleCollectionsTask).perform();
  }

  @task
  private *getMessengerSettingLocaleIds(): TaskGenerator<string[]> {
    let languageSettings = yield this.store.findRecord(
      'messenger-settings/languages',
      this.appService.app.id,
    );
    let nonDefaultPermittedLocales = languageSettings.supportedLocales
      .filter((locale: any) => locale.isPermitted)
      .map((locale: any) => locale.localeId);
    return [languageSettings.defaultLocale, ...nonDefaultPermittedLocales];
  }

  private getHelpCenterLocaleIds(): string[] {
    return this.helpCenterService.allSites.length > 0
      ? this.helpCenterService.allUniqueLocalesOrdered.map((locale: any) => locale.localeId)
      : [];
  }

  goToContent(
    contentType: EntityType,
    queryParams?: KnowledgeHubApiQueryParams,
    openInNewTab = false,
  ) {
    if (contentType === EntityType.Answer) {
      if (openInNewTab) {
        safeWindowOpen(
          this.router.urlFor('apps.app.automation.fin-ai-agent.custom-answers'),
          '_blank',
        );
        return;
      }
      this.router.transitionTo('apps.app.automation.fin-ai-agent.custom-answers');
    } else {
      if (openInNewTab) {
        safeWindowOpen(
          this.router.urlFor('apps.app.knowledge-hub.all-content', {
            queryParams: { ...KNOWLEDGE_BLANK_QUERY_PARAMS, types: contentType, ...queryParams },
          }),
          '_blank',
        );
        return;
      }
      this.router.transitionTo('apps.app.knowledge-hub.all-content', {
        queryParams: { ...KNOWLEDGE_BLANK_QUERY_PARAMS, types: [contentType], ...queryParams },
      });
    }
  }

  goToSyncSourceFolder(
    syncSource: SyncSourceWrapper,
    queryParams?: KnowledgeHubApiQueryParams,
    openInNewTab = false,
    withEntityType = true,
  ): void {
    if (!syncSource.folderId) {
      return;
    }
    if (openInNewTab) {
      safeWindowOpen(
        this.router.urlFor('apps.app.knowledge-hub.folder', syncSource.folderId, {
          queryParams: {
            ...(withEntityType ? { types: syncSource.entityType } : {}),
            ...KNOWLEDGE_BLANK_QUERY_PARAMS,
            ...queryParams,
          },
        }),
        '_blank',
      );
      return;
    }
    this.router.transitionTo('apps.app.knowledge-hub.folder', syncSource.folderId, {
      queryParams: {
        ...(withEntityType ? { types: syncSource.entityType } : {}),
        ...KNOWLEDGE_BLANK_QUERY_PARAMS,
        ...queryParams,
      },
    });
  }

  async goToFolder(
    folderId: number,
    queryParams?: KnowledgeHubApiQueryParams,
    openInNewTab = false,
  ) {
    await this.fetchFoldersOnce(true);
    if (openInNewTab) {
      safeWindowOpen(
        this.router.urlFor('apps.app.knowledge-hub.folder', folderId, {
          queryParams: {
            ...KNOWLEDGE_BLANK_QUERY_PARAMS,
            ...queryParams,
          },
        }),
        '_blank',
      );
      return;
    }
    this.router.transitionTo('apps.app.knowledge-hub.folder', folderId, {
      queryParams: {
        ...KNOWLEDGE_BLANK_QUERY_PARAMS,
        ...queryParams,
      },
    });
  }

  createArticle(queryParams: any = {}, openInNewTab = false) {
    this.createContent(EntityType.ArticleContent, queryParams, openInNewTab);
  }

  goToArticles() {
    this.goToContent(EntityType.ArticleContent);
  }

  createInternalArticle() {
    this.createContent(EntityType.InternalArticle);
  }

  goToInternalArticles() {
    this.goToContent(EntityType.InternalArticle);
  }

  createSnippet() {
    this.createContent(EntityType.ContentSnippet);
  }

  goToSnippets() {
    this.goToContent(EntityType.ContentSnippet);
  }

  goToExternalContent() {
    this.goToContent(EntityType.ExternalContent);
  }

  goToFiles() {
    this.goToContent(EntityType.FileSourceContent);
  }

  async findContent({
    contentId,
    contentType,
    contentTypeName,
    findRecordParams = {},
    peekFirst = false,
  }: {
    contentId: number;
    contentType?: EntityType;
    contentTypeName?: string;
    findRecordParams?: any;
    peekFirst?: boolean;
  }) {
    let emberDataModelName = contentTypeName
      ? KNOWLEDGE_HUB_CONTENT_TYPES_TO_DATA_STORES[contentTypeName]
      : contentType && KNOWLEDGE_HUB_ENTITY_TYPES_TO_DATA_STORES[contentType];

    if (!emberDataModelName) {
      throw new Error(`Unknown content type: ${contentType} or ${contentTypeName}`);
    }
    if (peekFirst) {
      let model = this.store.peekRecord(emberDataModelName, contentId);
      if (model) {
        return model;
      }
    }
    return this.store.findRecord(emberDataModelName, contentId, findRecordParams);
  }

  async createContent(
    entityType: EntityType,
    queryParams: any = {},
    openInNewTab = false,
    openInContext = true,
  ) {
    if (entityType === EntityType.Answer) {
      return this.createCustomAnswer(openInNewTab);
    }
    try {
      await this.ensurePermissions(entityType);
    } catch (error) {
      return;
    }
    this.markOnboardingStepAsComplete();
    this.redirectToCreateNewContent(entityType, queryParams, openInContext, openInNewTab);
    this.trackAnalyticsEvent('clicked', objectNames[entityType]);
  }

  private redirectToCreateNewContent(
    entityType: EntityType,
    queryParams: any = {},
    openInContext = true,
    openInNewTab = false,
  ) {
    let entityName = objectNames[entityType];
    let routeName = openInContext
      ? this.router.currentRouteName
      : 'apps.app.knowledge-hub.all-content';

    this.knowledgeHubDrawerEditorService.createNewContent({
      routeName,
      activeContentType: entityName,
      ...queryParams,
      openInNewTab,
    });
  }

  async markOnboardingStepAsComplete() {
    try {
      await this.guideLibraryService.markStepCompleted(
        'guide_library_foundational_steps_add_support_content',
      );
    } catch (error) {
      return;
    }
  }

  createCustomAnswer(openInNewTab: boolean) {
    let route = `${this.appService.app.answersRoute}.new`;
    let queryParams = { language: null };

    if (openInNewTab) {
      safeWindowOpen(this.router.urlFor(route, { queryParams }), '_blank');
    } else {
      this.router.transitionTo(route, {
        queryParams,
      });
    }
  }

  async ensurePermissions(entityType?: EntityType) {
    let requiredPermission = '';
    switch (entityType) {
      case EntityType.ArticleContent:
        requiredPermission = CAN_CREATE_AND_EDIT_DRAFT_ARTICLES_PERMISSION;
        break;
      default:
        requiredPermission = CAN_MANAGE_KNOWLEDGE_BASE_CONTENT;
    }
    await this.permissionsService.checkPermission(requiredPermission);
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'knowledge_hub',
      context: 'all_content',
      place: 'all_content',
      ...metadata,
    });
  }

  async getOverviewChecklistAttributes(): Promise<void> {
    this.overviewChecklistAttributes = await get(
      '/ember/knowledge_base/overview/checklist_attributes',
      {
        app_id: this.appService.app.id,
      },
    );
  }

  private minimumCountForEntityType(entityType: EntityType): number {
    return entityType === EntityType.ConversationExcerpt
      ? MINIMUM_CONVERSATION_EXCERPTS
      : MINIMUM_SOURCE_COUNT;
  }

  private getMinimumContentCount(aiType: AIType): number {
    let contentTypes = aiType === 'agent' ? AI_AGENT_ENTITY_TYPES : AI_COPILOT_ENTITY_TYPES;
    let count = 0;
    contentTypes.forEach((entityType) => {
      if (this.usageSummary) {
        let summary = this.usageSummary[entityType];
        if (summary[aiType] >= this.minimumCountForEntityType(entityType)) {
          count++;
        }
      }
    });
    return count;
  }

  hasAddedMinimunRequiredSources(aiType: AIType): boolean {
    return this.getMinimumContentCount(aiType) >= MINIMUM_OPTIMIZE_AI_SOURCE_COUNT;
  }
}

declare module '@ember/service' {
  interface Registry {
    knowledgeHubService: KnowledgeHubService;
    'knowledge-hub-service': KnowledgeHubService;
  }
}
